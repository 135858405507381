.vi-react-hook-form {
  .form-label {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #294c60;
    overflow-wrap: break-word !important;
    word-break: break-word;
    display: inline-block;
    transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    margin-bottom: 5px;
  }

  .form-control {
    display: block;
    width: 100%;
    border: none;
    background-color: #ffffff;
    height: 40px;
    padding: 9px 12px;
    font-size: 14px;
    border-radius: 6px;
    box-shadow: 0 0 transparent, inset 0 0 0 1px rgb(17 43 134 / 10%);
    color: #343741;
    &.iem-feedback-rich-text {
      padding: 0px;
      .ql-container {
        height: 67px;
      }
    }
    &.invalid {
      background-image: linear-gradient(
        to top,
        #f25a69,
        #f25a69 2px,
        transparent 2px,
        transparent 100%
      );
      background-size: 100%;
      box-shadow: none;
    }

    &.add-before {
      padding: 9px 36px;
    }
    &.text-area-hook-form {
      height: 110px;
    }
  }

  .form-control:focus {
    background-image: linear-gradient(
      to top,
      $color-3AAFA9,
      $color-3AAFA9 2px,
      transparent 2px,
      transparent 100%
    );
  }

  .form-control:focus-visible {
    outline-style: none;
  }
  .error {
    color: #f25a69;
    font-size: 12px;
    margin-top: 5px;
  }
  .euiFieldText.euiFieldText-isInvalid {
    background-image: linear-gradient(
      to top,
      #f25a69,
      #f25a69 2px,
      transparent 2px,
      transparent 100%
    );
    background-size: 100%;
  }
}

.read-only-quil-default {
  h1 {
    font-size: 2em;
    font-weight: bold;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  all: initial;
  ol {
    list-style: auto;
    margin-left: 10px;
  }
  ul {
    list-style: disc;
    margin-left: 10px;
  }
  .ql-container.ql-snow {
    border-width: 0px;
  }
  .ql-editor {
    padding: 0px;
  }
}
.rich-text-error {
  position: relative;
  top: 10px;
}

// background-image: linear-gradient(to top, #f25a69, #f25a69 2px, transparent 2px, transparent 100%);
// background-size: 100%;
