.internal-error-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  .error-header {
    font-size: 48px;
    font-weight: 1000;
    line-height: 24px;
  }

  .error-message {
    font-size: 20px;
    color: #656565;
  }
}
