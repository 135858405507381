.slide-animation {
  transition: width 0.15s linear;
}

.navBar {
  top: 0;
  left: 0;
  z-index: 10;
  height: 100vh;
  position: relative;
  flex-direction: column;
  background-color: black;
}

.navBarMini {
  width: 88px;
}

.navBarVertical {
  width: 300px;
}

.iconButton {
  padding: 4px !important;
  top: 24px;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #fff !important;
}

.iconButton:hover {
  background-color: #fff !important;
}

.navToggleButton {
  position: absolute !important;
  right: 0;
  transform: translateX(50%);
  border: 1px solid gray !important;
}

.svgIcon {
  width: 16px;
  height: 16px;
}

.svgIconMini {
  transform: scaleX(-1);
}

.scrollbar {
  min-width: 0;
  min-height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.logoBox {
  padding-left: 28px;
  padding-top: 20px;
  padding-bottom: 8px;
}

.miniLogoBox {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
