@font-face {
  font-family: 'Open Sans Bold';
  src: url('../fonts/static/OpenSans/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Semi Bold';
  src: url('../fonts/static/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: $font-family-open-sans;
  src: url('../fonts/static/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Medium';
  src: url('../fonts/static/OpenSans/OpenSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Avenir;
  src: url('../fonts/Avenir/AvenirBlack/Avenir_Black.ttf') format('truetype');
}

body {
  font-family: $font-family-open-sans !important;
}

a {
  color: $color-3AAFA9;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px !important;
  gap: 12px;
  height: 64px !important;
  background: $color-ffffff !important;
  box-shadow: 0px 9px 28px 8px rgb(0 0 0 / 5%), 0px 6px 16px rgb(0 0 0 / 8%),
    0px 3px 6px -4px rgb(0 0 0 / 12%);
}

.ant-layout-content {
  overflow-y: auto;
  height: calc(100vh - 64px);
}

.user-select-none {
  user-select: none;
}
.content {
  background: $color-ffffff;
  padding: 0px 24px 24px;
}
.content-login {
  background: $color-ffffff;
  padding: 24px;
}

.twofa-modal.ant-modal {
  top: 6vh;

  .ant-modal-body {
    padding: 12px 24px;
  }

  .ant-btn:focus {
    border: 1px solid #348eda !important;
  }
}

.two-fa-otp {
  .ant-btn:focus {
    border: 1px solid #348eda !important;
  }
}

.input-otp.otp-wrapper {
  justify-content: flex-start;
  .input-otp__field {
    height: 50px;
  }
}
.p-4 {
  padding: 4px;
}

.p-5 {
  padding: 5px;
}

.px-8 {
  padding-right: 8px;
  padding-left: 8px;
}

.py-14 {
  padding-top: 14px;
  padding-right: 14px;
}

.px-16 {
  padding-right: 16px;
  padding-left: 16px;
}

.px-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.py-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-5 {
  padding-top: 5px;
}

.p-10 {
  padding: 10px;
}

.p-12 {
  padding: 12px;
}

.d-f {
  display: flex !important;
}

.d-if {
  display: inline-flex !important;
}

.d-b {
  display: block !important;
}

.d-ib {
  display: inline-block !important;
}

.fd-col {
  flex-direction: column !important;
}
.fd-row {
  flex-direction: row;
}

.ff-wrap {
  flex-flow: wrap;
}
.mtb-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.jc-sb {
  justify-content: space-between;
}
.jc-sa {
  justify-content: space-around;
}
.jc-e {
  justify-content: end;
}
.jc-c {
  justify-content: center !important;
}
.jc-fs {
  justify-content: flex-start;
}
.jc-fe {
  justify-content: flex-end;
}
.ai-c {
  align-items: center;
}
.ai-s {
  align-items: start !important;
}
.word-break {
  word-break: break-all !important;
}
.ai-b {
  align-items: baseline;
}

.ai-fe {
  align-items: flex-end;
}

.flex-1 {
  flex: 1;
}

.gap-2 {
  gap: 2px;
}
.gap-8 {
  gap: 8px !important;
}
.gap-4 {
  gap: 4px !important;
}

.gap-12 {
  gap: 12px;
}

.gap-14 {
  gap: 14px;
}

.gap-16 {
  gap: 16px !important;
}

.gap-28 {
  gap: 28px !important;
}
.gap-32 {
  gap: 32px !important;
}

.gap-40 {
  gap: 40px;
}

.gap-50 {
  gap: 50px;
}

.ta-c {
  text-align: center;
}

.gap-24 {
  gap: 24px !important;
}

.gap-10 {
  gap: 10px !important;
}

.gap-20 {
  gap: 20px !important;
}

.gap-250 {
  gap: 250px;
}

.m-auto {
  margin: auto;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-48 {
  margin-right: 48px !important;
}

.mr-36 {
  margin-right: 36px !important;
}
.ml-24 {
  margin-left: 24px;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-4 {
  margin-right: 4px !important;
}
.ml-4 {
  margin-left: 4px;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-4 {
  margin-top: 4px !important;
}
.mt-26 {
  margin-top: 26px;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-6 {
  margin-top: 6px;
}

.MuiTypography-h2 {
  font-size: 22px !important;
  font-weight: 590 !important;
  line-height: 26px !important;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-m20 {
  margin-top: -20px !important;
}

.mt-m30 {
  margin-top: -30px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mt-44 {
  margin-top: 44px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-56 {
  margin-top: 56px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.mb-26 {
  margin-bottom: 26px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-24 {
  margin: 24px !important;
}

.mt-n-14 {
  margin-top: -14px !important;
}

.mx-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-24 {
  padding: 24px;
}

.px-124 {
  padding-left: 124px;
  padding-right: 124px;
}

.px-140 {
  padding-left: 140px;
  padding-right: 140px;
}

.px-180 {
  padding-left: 180px;
  padding-right: 180px;
}

.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.px-72 {
  padding-left: 72px !important;
  padding-right: 72px !important;
}

.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-20 {
  padding: 20px;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mb-46 {
  padding-bottom: 46px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-16 {
  padding-top: 16px;
}

.p-16 {
  padding: 16px;
}

.p-16-24 {
  padding: 16px 24px;
}

.p-16-12 {
  padding: 16px 12px;
}

.p-32 {
  padding: 32px !important;
}

.p-8 {
  padding: 8px;
}

.p-l-24-t-5 {
  padding: 5px 24px;
}

.plr-10 {
  padding: 0 10px;
}

.pr-16 {
  padding-right: 16px;
}

.pr-24 {
  padding-right: 24px !important;
}

.pt-8 {
  padding-top: 8px;
}

.pt-24 {
  padding-top: 24px;
}

.br-4 {
  border-radius: 4px;
}

.br-6 {
  border-radius: 6px;
}

.br-8 {
  border-radius: 8px !important;
}

.br-12 {
  border-radius: 12px !important;
}

.h-100 {
  height: 100%;
}

.h-200 {
  height: 200px !important;
}

.h-396 {
  height: 396px !important;
}

.h-380 {
  height: 380px !important;
}

.h-22 {
  height: 22px;
}

.h-32 {
  height: 32px !important;
}

.h-64 {
  height: 64px;
}

.h-56 {
  height: 56px;
}

.min-h-22 {
  min-height: 22px;
}

.h-64 {
  height: 64px;
}

.h-56 {
  height: 56px;
}

.h-fit {
  height: fit-content !important;
}

.min-h-22 {
  min-height: 22px;
}

.w-fill {
  width: -webkit-fill-available;
}

.w-30 {
  width: 30%;
}

.w-50 {
  width: 50%;
}

.mw-500 {
  max-width: 500px;
}

.w-500-h-500 {
  width: 500px;
  height: 500px;
}

.w-75 {
  width: 75%;
}

.w-60 {
  width: 60%;
}

.w-100 {
  width: 100% !important;
}

.w-200 {
  width: 200px;
}

.w-130 {
  width: 130px;
}

.w-94 {
  width: 94% !important;
}

.w-250 {
  width: 250px;
}

.w-450 {
  width: 450px;
}

.w-645 {
  width: 645px;
}

.w-400px {
  min-width: 400px;
  max-width: 400px;
}

.w-85px {
  min-width: 85px;
  max-width: 85px;
}
.w-700px {
  min-width: 700px;
  max-width: 700px;
}

.w-30-h-30 {
  width: 30px;
  height: 30px;
}

.w-4-h-8-br-2 {
  width: 4px;
  height: 8px;
  border-radius: 4px;
  border: 0.5px solid #acacac;
}

.color-389E0D {
  color: #389e0d;
}

.color-0050B3 {
  color: #0050b3;
}

.color-3AAFA9 {
  color: $color-3AAFA9;
}

.color-141414 {
  color: #141414;
}

.color-d32f2f {
  color: #d32f2f !important;
}

.color-434343 {
  color: $color-434343;
}

.color-BFBFBF {
  color: $color-BFBFBF;
}

.color-ACACAC {
  color: #acacac;
}

.color-F25A69 {
  color: #f25a69;
}

.color-595959 {
  color: #595959;
}

.color-979797 {
  color: #979797;
}

.color-9E9E9E {
  color: #9e9e9e;
}

.bg-E6F7FF {
  background-color: #e6f7ff;
}

.bg-F8F8F8 {
  background-color: #f8f8f8;
}

.bg-F5F5F5 {
  background-color: #f5f5f5;
}

.bg-fff2f0 {
  background-color: #fff2f0;
}

.bg-f6ffed {
  background-color: #f6ffed;
}

.bg-fffbe6 {
  background-color: #fffbe6;
}

.bg-f5f7fa {
  background-color: #f5f7fa;
}

.bg-F0F0F0 {
  background-color: #f0f0f0;
}

.bg-99D3D1 {
  background-color: #99d3d1 !important;
}

.bg-73C5C1 {
  background-color: #73c5c1 !important;
}

.bg-3AAFA9 {
  background-color: #3aafa9 !important;
}

.border-bottom {
  border-bottom: 0.5px solid #dfdfdf;
}

.border-AED6D0 {
  border: 1px solid #aed6d0;
}

.border-b-f0f0f0 {
  border-bottom: 1px solid #f0f0f0;
}

.border-ffe58f {
  border: 1px solid #ffe58f;
}

.border-b7eb8f {
  border: 1px solid #b7eb8f;
}

.border-ffccc7 {
  border: 1px solid #ffccc7;
}

.border-d9d9d9 {
  border: 1px solid #d9d9d9;
}

.disable-div {
  pointer-events: none;
}

.br-15-E5E5E5 {
  border-radius: 15px;
  border: 2px solid #e5e5e5;
}

.br-8-E5E5E5 {
  border-radius: 8px;
  border: 1px solid #e5e5e5;
}

.w-fill {
  width: -webkit-fill-available;
}

.mr-10 {
  margin-right: 10px;
}

.ml-8 {
  margin-left: 8px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-5 {
  padding-top: 5px;
}

.pt-24 {
  padding-top: 24px;
}

.mr-2 {
  margin-right: 2px !important;
}

.mr-8 {
  margin-right: 8px !important;
}
.mr-16 {
  margin-right: 16px !important;
}

.lh-32 {
  line-height: 32px;
}

.lh-24 {
  line-height: 24px;
}

.lh-22 {
  line-height: 22px;
}

.lh-18 {
  line-height: 18px;
}

.lh-16 {
  line-height: 16px;
}

.color-FF4D4F {
  color: $color-FF4D4F;
}

.color-888888 {
  color: $color-888888 !important;
}

.color-FFFFFF {
  color: $color-ffffff !important;
}

.bg-FFFFFF {
  background-color: $color-ffffff !important;
}

.color-00000085 {
  color: #00000085;
}
.color-black {
  color: #000000;
}

.color-333333 {
  color: #333333 !important;
}

.color-8C8C8C {
  color: $color-8C8C8C;
}

.color-fff-65 {
  color: #ffffff;
}

.bg-FFE7BA {
  background-color: #ffe7ba;
}

.background-white {
  background-color: white;
}

.color-294C60 {
  color: $color-294C60;
}

.ai-fs {
  align-items: flex-start;
}

.as-s {
  align-self: start !important;
}

.background-5 {
  background-color: #ecf9f8;
}

.asterisk-red {
  color: $color-F5222D !important;
  margin-right: 4px;
}

.create-user-group {
  margin-left: unset !important;
}

.total-count {
  font-weight: $font-weight-600;
}

.total-text {
  font-weight: $font-weight-400;
  color: $color-8C8C8C;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.copy {
  color: $color-3AAFA9 !important;
}

.ff-open-sans-regular {
  font-family: $font-family-open-sans-regular;
}
.ff-open-sans-semi-bold {
  font-family: $font-family-open-sans-semi-bold;
}
.link-color {
  color: #3aafa9;
}

.fs-16 {
  font-size: $font-size-16 !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: $font-size-12;
}
.fs-24 {
  font-size: 24px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-15 {
  font-size: 15px;
}

.fs-22 {
  font-size: 22px;
}

.fs-42 {
  font-size: 42px;
}
.p-48 {
  padding: 48px;
}
.ml-48 {
  margin-left: 48px;
}

.ml-n-16 {
  margin-left: -16px;
}

.d-none {
  display: none;
}

.m-w-100 {
  max-width: 100px;
}

.min-w-100 {
  min-width: 100px;
}

.min-w-60 {
  min-width: 60px;
}

.max-w-max {
  max-width: max-content;
}

.max-w-800 {
  max-width: 800px;
}

.max-w-550 {
  max-width: 550px;
}

.min-w-451 {
  min-width: 451px;
}

.m-w-120 {
  max-width: 120px;
}

.underline {
  text-decoration-line: underline;
}

.transform-none {
  text-transform: none !important;
}

.link-text {
  color: $color-3AAFA9 !important;
  cursor: pointer;
}

.secondaryButton {
  background-color: #00b1aa33 !important;
  color: #3aafa9 !important;
}

.user-group-name {
  color: $color-3AAFA9;
  font-family: $font-family-open-sans;
  font-weight: $font-weight-600;
  cursor: pointer;
  width: fit-content;
  display: block;
}

.width-max-content {
  width: max-content;
}

.user-group-count {
  font-family: $font-family-open-sans;
  font-weight: $font-weight-600;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: $color-3AAFA9;
  cursor: pointer;
}

.cursor-na {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-510 {
  font-weight: 510 !important;
}

.font-weight-590 {
  font-weight: 590;
}

.color-5c6bc0 {
  color: #5c6bc0;
}

.color-595959 {
  color: #595959;
}

.color-faad14 {
  color: #faad14;
}

.text-center {
  text-align: center;
}

.w-fit {
  width: fit-content;
}

.user-group-permission {
  font-family: $font-family-open-sans;
  font-weight: $font-weight-600;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: $color-geek-blue;
  cursor: pointer;
}

.user-group-info-form-wrapper {
  padding: 24px !important;

  .main-label {
    font-weight: 600;
  }

  .child-label {
    color: $color-8C8C8C;
  }

  .user-role-tag {
    padding: 1px 8px;
    gap: 4px !important;
    margin: unset;

    .user-count {
      width: max-content;
      height: max-content;
      margin: 4px 0;
    }
  }
}

.user-group-model-tooltip {
  .ant-tooltip-inner {
    padding: 0 !important;
    max-height: 200px;
    height: auto;
    overflow-y: scroll;
  }
}

.skip-batch-check > .ant-row {
  display: grid;
  grid-template-columns: 30% 70%;
}

.group-card {
  .ant-card-head {
    height: 3.5625em;
  }
  .ant-card-body {
    height: calc(100vh - 426px);
    overflow: auto;
  }

  .card-title {
    font-family: $font-family-open-sans;
    font-style: normal;
    font-weight: $font-weight-600;
    line-height: 24px;
  }

  .modules-parent {
    font-weight: $font-weight-600;
  }

  .modules-sub-page {
    margin-top: 10px;
    margin-left: 16px;
  }

  .no-assigned-users-text {
    text-decoration: underline;
    color: $color-3AAFA9;
    cursor: pointer;
  }
}

.ant-card-body {
  padding: 12px 24px 24px 24px !important;
}

.divide-header-content {
  visibility: hidden;
}

.ant-divider-horizontal {
  margin: 12px 0px !important;
}

.heading-permission {
  font-family: $font-family-open-sans;
  font-style: normal;
  font-weight: $font-weight-600;
  line-height: 22px;
}

.assigned-users {
  .user-name {
    font-family: $font-family-open-sans;
    font-style: normal;
    font-weight: $font-weight-600;
    line-height: 22px;
  }
  .user-department {
    font-family: $font-family-open-sans;
    font-style: normal;
    font-weight: $font-weight-400;
    line-height: 22px;
    color: $color-8C8C8C;
  }
  .user-remove {
    font-family: $font-family-open-sans;
    font-style: normal;
    font-weight: $font-weight-400;
    line-height: 22px;
    color: $color-F5222D;
    cursor: pointer;
  }
}

.ant-empty-normal .ant-empty-image {
  height: 100px !important;
}
.ant-empty-image svg {
  width: 134px !important;
}

.save-group-info {
  margin-left: 10px;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  color: $color-F5222D !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: $color-3AAFA9 !important;
}
.ant-picker-focused,
.ant-picker:hover {
  border-color: $color-3AAFA9 !important;
}

.batch-page-drawer {
  .ant-drawer-content {
    padding: 0px !important;
    overflow: unset !important;
  }
  .ant-drawer-body {
    padding: 0px !important;
    overflow: unset !important;
  }
  .ant-layout-content {
    padding: 0px !important;
    margin: 0px !important;
  }
  .ant-tabs-nav-wrap {
    padding-left: 24px;
    background: #fafafa;
  }
  .ant-tabs-content-holder {
    padding: 0px 24px 0px 24px;
  }
  .ant-modal-footer {
    display: none !important;
  }
  .ant-table-content {
    overflow: auto;
  }
}

.batch-page-footer {
  position: fixed;
  bottom: 0;
  width: 82%;
  border-top: 1px solid #f0f0f0;
  margin-left: -24px;
  height: 50px;
  padding-top: 10px;
}
.batch-page-done {
  margin-left: 90.5%;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $color-3AAFA9 !important;
  border-color: $color-3AAFA9 !important;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-checked:after {
  border-color: $color-3AAFA9 !important;
}
.ant-tooltip-inner {
  background: #001529 !important;
}
.ant-tooltip-arrow-content:before {
  background: #001529 !important;
}
.internDetailFixedContent {
  box-shadow: 1px 2px 2px 1px $color-404040;
  .ant-card-body {
    padding-bottom: 0px !important;
  }
}

.filter-drawer {
  .footer-btn {
    float: right;
  }
}

.drawer-footer {
  position: fixed;
  width: 100%;
  bottom: 5px;
}

.ant-form-item-has-error {
  .ant-radio-inner,
  .ant-checkbox-inner {
    border: 1px solid $color-F5222D;
  }
}

.color-1a1c21 {
  color: #1a1c21;
}

.color-F5222D {
  color: $color-F5222D;
}

.color-link {
  color: #348eda !important;
}

.opacity-60 {
  opacity: 60%;
}

.error-text {
  color: $color-F5222D;
  font-size: $font-size-12;
}
.ant-select-item-empty {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.table-empty-text {
  font-family: $font-family-open-sans;
  font-style: normal;
  font-weight: $font-weight-600;
  line-height: 22px;
  font-size: $font-size-16;
}

.c-pointer {
  cursor: pointer;
}

// Remove Input type[number] arrow
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  outline: none;
}

/* Firefox */
input {
  appearance: textfield;
  -moz-appearance: textfield;
  outline: none;
}
.badge-round {
  height: max-content !important;
  border-radius: 50px !important;
}
.cursor-none {
  cursor: not-allowed !important;
}

.dashboard-background.ant-image-img {
  width: 200px;
  margin: 250px 50%;
  padding: 10px 10px;
  transform: translate(-50%, 0);
  opacity: 40%;
}
.read-only-quil {
  .ql-container.ql-snow {
    border-width: 0px;
  }
  .ql-editor {
    padding: 0px;
  }
}
.error-form-value {
  color: #f25a69;
}
.mb-4 {
  margin-bottom: 4px !important;
}

.primary-color {
  color: $color-3AAFA9 !important;
}

.my-custom-date-picker .rmdp-header {
  background-color: $color-3AAFA9 !important;
}

/* Change the text color */
.my-custom-date-picker .rmdp-header h1 {
  color: $color-3AAFA9 !important;
}

/* Change the selected date color */
.my-custom-date-picker .rmdp-day.selected {
  background-color: $color-3AAFA9 !important;
  color: $color-3AAFA9 !important;
}
.rmdp-today {
  span {
    background: #3aafa98c !important;
  }
}
.rmdp-selected {
  span {
    background: $color-3AAFA9 !important;
  }
}
.rmdp-week-day,
.rmdp-arrow {
  color: $color-3AAFA9 !important;
}
.rmdp-arrow-container:hover {
  background: $color-3AAFA9 !important;
}
.rmdp-container {
  width: 100%;
  .rmdp-input {
    height: 30px;
    width: 100%;
  }
}

.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background-color: #3aafa98c !important;
}

.light-text-color {
  color: #00000073;
}
.background-F5F5F5 {
  background: $color-F5F5F5;
}
.background-FAFAFA {
  background: linear-gradient(0deg, #fafafa, #fafafa),
    linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
}

.bg-404040 {
  background-color: #404040 !important;
}

.bg-F0F5FF {
  background-color: #f0f5ff !important;
}

.bg-FFF7E6 {
  background-color: #fff7e6 !important;
}

.bg-E0E5EE {
  background-color: #e0e5ee !important;
}

.color-FA8C16 {
  color: #fa8c16;
}

.color-000000 {
  color: $color-000000 !important;
}

.color-69707D {
  color: $color-69707D !important;
}

.color-17252A {
  color: $color-17252A !important;
}

.color-52C41A {
  color: $color-52C41A !important;
}

.color-2F54EB {
  color: $color-2F54EB !important;
}

.color-637381 {
  color: #637381;
}

.color-262626 {
  color: #262626;
}

.color-000000DE {
  color: #000000de;
}

.color-00000073 {
  color: #00000073;
}

.color-000000d9 {
  color: #000000d9;
}

.width-fit {
  width: fit-content;
}

.ant-modal-confirm-btns {
  .ant-btn.ant-btn-default.button,
  .ant-btn.ant-btn-primary.buttonFilled {
    border-radius: 8px !important;
  }
}

.bold-text {
  font-weight: $font-weight-700;
  font-size: $font-size-14;
  line-height: $line-height-22;
  color: $color-000000;
}

.css-1nmdiq5-menu > div {
  max-height: 200px !important;
}

.ant-timeline-item-head-blue {
  border: 3px solid rgb(58, 175, 169) !important;
}

.text-sf-regular {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
    Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
  font-weight: $font-weight-400;
}

.text-sf-regular-14px-20px {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
    Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
  font-weight: $font-weight-400;
  font-size: 14px;
  line-height: 20px;
}

.text-underline {
  text-decoration: underline;
}

.external-link {
  color: $color-3AAFA9 !important;
  &:hover {
    text-decoration: underline;
  }
}

.red-dot {
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  background: #f14235;
  border-radius: 50%;
}
.word-wrap {
  word-wrap: break-word !important;
}
.line-through {
  text-decoration: line-through !important;
}

.fs-17 {
  font-size: 17px;
}
.fs-14 {
  font-size: 14px;
}

.fs-10 {
  font-size: 10px;
}

.text-sf-bold,
.text-sf-semi-bold,
.text-sf-regular {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
    Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
  font-weight: $font-weight-700;
}

.text-sf-semi-bold {
  font-weight: $font-weight-600;
}

.text-sf-regular {
  font-weight: $font-weight-400;
}
.react-multi-error {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
}

.acceptance-label {
  color: $color-8C8C8C;
}

.semi-bold {
  font-family: $font-family-open-sans-semi-bold;
  color: $color-262626;
  font-style: $font-style-normal;
  font-weight: $font-weight-400;
}

.content {
  position: relative;
  .footer-button-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
  }
}

.copy-icon {
  & svg {
    fill: $color-3AAFA9 !important;
  }
}

.cp-tabs {
  .ant-tabs-nav {
    margin: 0 !important;
  }
}

.cp-tabs.video-application-tabs {
  margin-top: -16px;
}

.ant-descriptions-row > td,
.ant-descriptions-row > th {
  background-color: $color-ffffff !important;
}

.overflow-hidden {
  overflow: hidden;
}

.pd-block {
  font-family: $font-family-open-sans;
  h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin: 0px;
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px;
    line-height: 22px;
    width: 90%;
  }
}

.program-fee-wrapper-detail {
  padding: 14px;
  background-color: #f5f7fa;
  border-radius: 3px;
  margin-bottom: 12px;
}

.application-flow-badge {
  .MuiChip-deletable {
    border: 1px solid #00b1aa;
    color: #00b1aa;
    margin-bottom: 16px;
    margin-right: 16px;
    padding: 18px 16px;
    font-size: 16px;
    line-height: 24px;
  }
}

.application-flow-badge.suggestion-box {
  border: 1px solid rgb(19 34 149 / 20%);
  border-radius: 8px;
  min-height: 176px;
  // width: 65vw;

  .MuiChip-clickable {
    background: #f5f7fa;
    border: 1px solid #d3dae6;
    border-radius: 20px;
    color: #294c60;
    margin-bottom: 16px;
    margin-right: 16px;
    padding: 18px 16px;
    font-size: 16px;
    line-height: 24px;
  }
}

.grey {
  color: #69707d;
}

.ant-typography-expand {
  color: rgba(58, 175, 169, 1) !important;
}

.pos-rel {
  position: relative;
}

.pos-fixed {
  position: fixed;
}

.pos-abs {
  position: absolute;
}

.pos-top-right {
  top: 10px;
  right: 10px;
}

.z-1 {
  z-index: 1;
}

.z-10 {
  z-index: 10;
}

.right-40 {
  right: 40px;
}

.text-wrap {
  text-wrap: wrap;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.top-0 {
  top: 0;
}

.flex-1 {
  flex: 1 1 0%;
}

.custom-delete-icon {
  color: #262626 !important;
}
